<template>
    <div class="eod-measurement">
        <ul class="input-error" v-if="errors && errors.length > 0">
            <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
        </ul>
        <template v-if="value">
            <template v-if="measurementType.valueConfigList">
                <eod-measurement-list-range ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.valueConfigList.itemType == 'RANGE'"></eod-measurement-list-range>
                <eod-measurement-list-value-text ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.valueConfigList.itemType == 'VALUE_TO_TEXT'"></eod-measurement-list-value-text>
                <eod-measurement-list-range-text ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.valueConfigList.itemType == 'RANGE_TO_TEXT'"></eod-measurement-list-range-text>
                <eod-measurement-list-company ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.valueConfigList.itemType == 'COMPANY'"></eod-measurement-list-company>
                <eod-measurement-list-project ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.valueConfigList.itemType == 'PROJECT'"></eod-measurement-list-project>
                <eod-measurement-list-user ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.valueConfigList.itemType == 'USER'"></eod-measurement-list-user>
            </template>
            <template v-else>
                <eod-measurement-text ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="value.value" @input="valueChanged"
                    v-if="measurementType.formConfig.type == 'text'"></eod-measurement-text>
                <eod-measurement-scale ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.formConfig.type == 'scale'"></eod-measurement-scale>
                <eod-measurement-number ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.formConfig.type == 'number'"></eod-measurement-number>
                <eod-measurement-yesno ref="measurement" :readonly="readonly" :measurementType="measurementType"
                    :value="parseFloat(value.value)" @input="valueChanged"
                    v-if="measurementType.formConfig.type == 'yesno'"></eod-measurement-yesno>
            </template>
            <div class="eod-measurement-actions d-flex">
                <div v-if="value.value != null && !readonly">
                    <label v-if="measurementType.formConfig.photo" :for="'photo_' + id"
                        class="btn-icon"><v-icon>mdi-image-plus</v-icon>
                        <input style="display:none;" :id="'photo_' + id" class="hidden" type="file" multiple
                            accept="image/*" @change="addImage($event)">
                    </label>
                    <v-btn icon v-if="measurementType.formConfig.comment"
                        @click="addComment"><v-icon>mdi-comment-plus-outline</v-icon></v-btn>
                </div>
                <v-spacer></v-spacer>
                <v-btn icon @click="showEvolutionDialog"><v-icon>mdi-finance</v-icon></v-btn>
                <eod-dialog v-if="evolutionDialogActive" v-model="evolutionDialogActive" :title="measurementType.name"
                    icon="mdi-finance" iconColor="primary" okText="Sluiten" @okAction="evolutionDialogActive = false"
                    width="800">
                    <template v-slot:headerActions>
                        <eod-time-selector ref="timeSelector" v-model="evolutionTimeRange"
                            @input="evolutionTimeRangeChanged"></eod-time-selector>
                    </template>
                    <div style="width: 100%;height:400px;" v-if="evolutionFrom && evolutionUntil">
                        <eod-evolution-graph ref="evolutionGraph" :predictionValue="value.value"  :measurementTypeId="measurementType?measurementType.id:null" :filters="{projectId: projectId, productId:product?product.id:null, jobTypeId:jobTypeId}" :from="evolutionFrom" :until="evolutionUntil"></eod-evolution-graph>
                    </div>
                    <template v-slot:actions>
                        <v-btn @click="evolutionDialogActive = false" rounded text>Sluiten</v-btn>
                        <v-spacer></v-spacer>
                        <!--<v-btn v-if="jobTypeId" rounded color="primary" depressed
                            :to="{ name: 'dashboard.generate', query: { project_id: projectId, job_type_id: jobTypeId } }"
                            target="_blank">Toon dashboard</v-btn>-->
                    </template>
                </eod-dialog>
            </div>
            <div v-if="value.comments && value.comments.length > 0" class="clearfix">
                <v-divider class="mt-2 mb-2"></v-divider>
                <eod-comment v-for="(comment, index) in    value.comments   " :key="'comment_' + index" :readonly="readonly"
                    v-model="value.comments[index]" @delete="deleteComment(index);"></eod-comment>
            </div>
            <div v-if=" value.storageObjects && value.storageObjects.length > 0 " class="clearfix">
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-row>
                    <v-col sm="6" md="4" lg="3" xl="2" v-for="(   image, index   ) in    value.storageObjects   "
                        :key=" index ">
                        <v-card>
                            <v-img @click=" showLightbox(index) "
                                :src=" image.id ? $eod.getImageUrl(image.key) : 'data:image/jpeg;base64,' + image.key "></v-img>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div v-if=" createTask && ((value.storageObjects && value.storageObjects.length > 0) || (value.comments && value.comments.length > 0)) "
                class="clearfix">
                <v-divider class="mt-2 mb-2"></v-divider>
                <v-tooltip bottom>
                    <template v-slot:activator=" { on, attrs } ">
                        <v-btn icon v-on=" on " v-bind=" attrs "
                            @click=" $emit('onCreateTask', value) "><v-icon>mdi-clipboard-plus-outline</v-icon></v-btn>
                    </template>
                    <span>Workflow aanmaken op basis van opmerking</span>
                </v-tooltip>
            </div>
        </template>
    </div>
</template>
<style lang="scss" scoped>
.clearfix {
    clear: both;
}
</style>
<script>
import eodMeasurementScale from './fieldtypes/eod-measurement-scale.vue';
import eodMeasurementNumber from './fieldtypes/eod-measurement-number.vue';
import eodMeasurementText from './fieldtypes/eod-measurement-text.vue';
import eodMeasurementYesno from './fieldtypes/eod-measurement-yesno.vue';
import eodMeasurementListRange from './fieldtypes/eod-measurement-list-range.vue';
import eodMeasurementListValueText from './fieldtypes/eod-measurement-list-value-text.vue';
import eodMeasurementListRangeText from './fieldtypes/eod-measurement-list-range-text.vue';
import eodMeasurementListCompany from './fieldtypes/eod-measurement-list-company.vue';
import eodMeasurementListProject from './fieldtypes/eod-measurement-list-project.vue';
import eodMeasurementListUser from './fieldtypes/eod-measurement-list-user.vue';
import eodComment from './eod-comment.vue';
import Lightbox from './lightbox.js';
import ImageLib from './../classes/imageLib';
import eodDialog from './eod-dialog.vue';
import eodEvolutionGraph from './eod-evolution-graph.vue';
import eodTimeSelector from './eod-time-selector.vue';
import {
    v4 as uuidv4
} from 'uuid';

export default {
    name: 'eod-measurement',
    components: {
        eodMeasurementScale,
        eodMeasurementNumber,
        eodMeasurementText,
        eodMeasurementYesno,
        eodMeasurementListRange,
        eodMeasurementListValueText,
        eodMeasurementListRangeText,
        eodMeasurementListCompany,
        eodMeasurementListProject,
        eodMeasurementListUser,
        eodComment,
        eodDialog,
        eodEvolutionGraph,
        eodTimeSelector
    },
    props: {
        readonly: {
            type: Boolean,
            default: false
        },
        createTask: {
            type: Boolean,
            default: false
        },
        value: Object,
        product: Object,
        measurementType: Object,
        cell: Object,
        projectId: String,
        jobTypeId: String,
    },
    data() {
        return {
            errors: [],
            showComment: false,
            evolutionDialogActive: false,
            evolutionTimeRange: { relative: require('./../classes/settings').relativeTimeRanges[1] },
            evolutionFrom: this.$moment().add(-1, 'years'),
            evolutionUntil: this.$moment(),
            id: uuidv4(),
            lastQuery:{
                evolutionFrom: null,
                evolutionUntil:null,
            }
        }
    },
    created() {
        const defaultTimeRangeSetting = this.$eod.getOrganizationSetting('measurements.trendgraph.defaultRange');
        if (defaultTimeRangeSetting && defaultTimeRangeSetting.value) {
            this.evolutionTimeRange.relative = require('./../classes/settings').relativeTimeRanges[parseInt(defaultTimeRangeSetting.value)];
        }
    },
    mounted() {
        if (this.value && this.value.comments && this.value.comments.length > 0) {
            this.showComment = true;
        }

        if (this.product) {
            let val = this.value;
            val.product = this.product;
            this.$emit('input', val);
        }
    },
    watch: {
        product(newVal) {
            this.addProductToMeasurement(newVal);
        }
    },
    methods: {
        addProductToMeasurement(product) {
            let val = this.value;
            val.product = product;
            this.$emit('input', val);
        },
        evolutionTimeRangeChanged(val) {

            if(this.lastQuery.evolutionFrom == val.from && this.lastQuery.evolutionUntil == val.until){
                return false;
            }

            this.evolutionFrom = val.from;
            this.evolutionUntil = val.until;

            this.lastQuery.evolutionFrom = val.from;
            this.lastQuery.evolutionUntil = val.until;

            this.$nextTick(() => {
                if (this.$refs.evolutionGraph) {
                    this.$refs.evolutionGraph.loadData();
                }
            })
        },
        async showEvolutionDialog() {
            this.evolutionDialogActive = true;
        },
        valueChanged(value) {
            let val = this.value;
            val.value = parseFloat(value);
            this.$emit('input', val);

            this.$forceUpdate();
        },
        refresh() {
            this.$forceUpdate();
            this.$refs.measurement.$forceUpdate();
        },
        validate() {
            this.errors = [];
            if (this.measurementType.formConfig.comment && this.measurementType.formConfig.commentRequired && (!this.value.comments || this.value.comments.length == 0)) {
                this.errors.push('Commentaar is verplicht!');
            }

            if (this.measurementType.formConfig.photo && this.measurementType.formConfig.photoRequired && (!this.value.storageObjects || this.value.storageObjects.length == 0)) {
                this.errors.push('Foto is verplicht!');
            }

            if (this.measurementType.formConfig.required && (!this.value.value || this.value.value == '')) {
                this.errors.push('Veld is verplicht!');
            }

            if (this.errors.length > 0) {
                return false;
            }

            return true;
        },
        addImage(event) {

            const val = this.value;

            if (!val.storageObjects) {
                val.storageObjects = [];
            }

            let self = this;

            if (event.target.files[0]) {
                for (let i = 0; i < event.target.files.length; i++) {
                    const file = event.target.files[i];

                    ImageLib.readFile(file).then(result => {
                        ImageLib.resize(result, 1200).then(url => {
                            const parts = url.split(',');
                            val.storageObjects.push({ id: null, key: parts[1], name: file.name });
                            self.$emit('input', val);
                            self.$forceUpdate();
                        });
                    });
                }
            }
        },
        addComment() {
            const item = this.value;

            if (!item.comments) {
                item.comments = [];
            }

            item.comments.push({
                createdAt: this.$moment().toISOString(),
                userId: this.$eod.getUser().id,
                organizationId: this.$eod.getRealm(),
                content: ''
            });

            this.$emit('input', item);
            this.$forceUpdate();
        },
        deleteComment(index) {
            let val = this.value;
            val.comments.splice(index, 1);
            this.$emit('input', val);
            this.$forceUpdate();
        },
        deleteImage(index) {
            this.value.storageObjects.splice(index, 1);
            this.$forceUpdate();
        },
        showLightbox(index) {
            var storageObjects = [];
            for (let i = 0; i < this.value.storageObjects.length; i++) {
                const el = this.value.storageObjects[i];

                if (el.id) {
                    storageObjects.push(this.$eod.getImageUrl(el.key));
                } else {
                    storageObjects.push('data:image/jpeg;base64,' + el.key);
                }
            }

            let options = {
                title: this.value.name,
            };

            if (!this.readonly) {
                options.delete = this.deleteImage;
            }

            var lightbox = new Lightbox(storageObjects, index, options);

            lightbox.show();
        }
    }
}
</script>