<template>
    <div class="eod-job-card__wrapper">
        <div class="eod-job-card__header-actions">
        <v-btn v-if="showDelete" color="transparent" depressed @click="$emit('delete', value)" fab small><v-icon :class="(getStatusCode(value).textColor != 'dark'?'white--text':'')">mdi-trash-can-outline</v-icon></v-btn>
        </div>
    <v-card class="eod-job-card" :disabled="!getStatusCode(value).calendar_enabled || disabled" @click="$emit('click', value)">
        <div class="eod-job-card__title text-center" :class="(showDelete?'pr-9':'')+' '+(extraClass?extraClass:'')+' '+(getStatusCode(value).textColor != 'dark'?'white--text':'')" :style="'background-color:'+getStatusCode(value).color+';'">{{value.parent.name}}</div>
        <slot name="subheader"></slot>
        <div class="eod-job-card__content">
        <div class="mb-1" v-if="value.taskType">
          <v-icon left>mdi-clipboard-text-outline</v-icon>{{value.taskType.name}}
        </div>
        <div class="mb-1" v-if="value.project">
          <v-icon left>mdi-folder-open-outline</v-icon>{{value.project.name}}
        </div>
        <div class="mb-1" v-if="value.project.locations && value.project.locations[0]">
          <v-icon left>mdi-map-marker-radius</v-icon>{{value.project.locations[0].streetAddress}}, {{value.project.locations[0].postalCode}} {{value.project.locations[0].locality}}
        </div>
        <div class="mb-1 d-flex align-items-start" v-if="value.parent.description && value.parent.description != ''">
                  <v-icon left>mdi-text</v-icon>
                  <div class="text-caption">
                  <eod-rte :value="value.parent.description" disabled></eod-rte>
                </div>
            </div>
        </div>
    </v-card>
    <div class="eod-job-card__actions elevation-1 align-items-center d-flex grey lighten-3 rounded-xl px-4 py-1">
            <v-chip class="mr-2"  small :color="getStatusCode(value).color" :dark="getStatusCode(value).textColor != 'dark'"><v-icon small v-html="getStatusCode(value).icon"></v-icon></v-chip>
            <div>{{$moment(value.dueAfterDate).format('HH:mm')}} - {{$moment(value.dueBeforeDate).format('HH:mm')}}</div>
          <v-spacer></v-spacer>
            <v-btn @click="showMediaDialog = true" class="mr-3" v-if="(value.project.storageObjects && value.project.storageObjects.length > 0)" icon><v-icon>mdi-image-multiple</v-icon>{{value.project.storageObjects.length}}</v-btn>
            <v-btn @click="showProductsDialog = true" class="mr-3" v-if="(value.products && value.products.length > 0) || (value.productTypes && value.productTypes.length > 0)" icon><v-icon>mdi-package-variant</v-icon>{{value.products.length+value.productTypes.length}}</v-btn>
            <v-btn @click="showContactsDialog = true" class="mr-3" v-if="value.project.users && value.project.users.length > 0" icon><v-icon>mdi-account-outline</v-icon>{{value.project.users.length}}</v-btn>
            <v-btn @click="showLocationsDialog = true" class="mr-3" v-if="value.project.locations && value.project.locations.length > 0" icon :color="showLocationAlert()?'accent':''"><v-icon>mdi-map-marker</v-icon>{{value.project.locations.length}}</v-btn>
          <v-btn icon @click="$emit('showInfo', value)"><v-icon>mdi-information</v-icon></v-btn>
          
        </div>
        <eod-products-dialog v-model="showProductsDialog" :products="value.products" :productTypes="value.productTypes"></eod-products-dialog>
        <eod-contacts-dialog v-model="showContactsDialog" :contacts="value.project.users"></eod-contacts-dialog>
        <eod-locations-dialog v-model="showLocationsDialog" :locations="value.project.locations"></eod-locations-dialog>
        <eod-media-dialog v-model="showMediaDialog" favourite :media="value.project.storageObjects"></eod-media-dialog>
    </div>
</template>
<style lang="scss">
.eod-job-card__wrapper{
    position: relative;

    .eod-job-card__header-actions{
        position: absolute;
        right: 1px;
        top: 1px;
        z-index: 5;
    }

    .eod-job-card{
        margin-bottom: 30px;
        border-radius: 20px;

        .eod-job-card__title{
            padding: .6rem 1rem;
        }

        .eod-job-card__content{
            padding: .8rem 1rem 2.4rem 1rem;
        }
    }

    .eod-job-card__actions{
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 100%;
    }
}


</style>
<script>
    import Task from './../models/task';
    import eodProductsDialog from './eod-products-dialog';
    import eodContactsDialog from './eod-contacts-dialog';
    import eodLocationsDialog from './eod-locations-dialog';
    import eodMediaDialog from './eod-media-dialog';
    import eodRte from './eod-rte.vue';

    export default {
        name:'eod-job-card',
        components:{
            eodProductsDialog,
            eodContactsDialog,
            eodLocationsDialog,
            eodMediaDialog,
            eodRte
        },
        props:{
            value: Object,
            extraClass: String,
            showDelete: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            showProductsDialog: false,
            showContactsDialog: false,
            showLocationsDialog: false,
            showMediaDialog: false,
        }),
        methods:{
            getStatusCode(job){
                return Task.getStatusCode(job.statusCode);
            },
            showLocationAlert(){
                for (let i = 0; i < this.value.project.locations.length; i++) {
                    const location = this.value.project.locations[i];
                    if(location.notes && location.notes != ''){
                        return true;
                    }
                }

                return false;
            }
        }
    }
</script>