<template>
    <v-dialog :value="value" @input="val => $emit('input', val)">
        <v-card class="rounded-lg">
            <v-card-title class="primary white--text">
                <v-icon dark left>mdi-package-variant</v-icon>Materiaal
                <v-spacer></v-spacer>
                <v-btn @click="$emit('input', false)" icon dark><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <eod-products-list :value="products"></eod-products-list>
            <template v-if="productTypes && productTypes.length > 0">
                <div class="px-4 primary lighten-5 py-2">Materiaaltypes</div>
                <eod-productTypes-list :value="productTypes"></eod-productTypes-list>
            </template>
        </v-card>
    </v-dialog>
</template>
<script>
import eodProductsList from './eod-products-list';
import eodProductTypesList from './eod-productTypes-list';

export default {
    name: 'eod-products-dialog',
    components:{
        eodProductsList,
        eodProductTypesList,
    },
    props:{
        value: Boolean,
        products: Array,
        productTypes: Array,
    }
}
</script>