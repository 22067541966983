import moment from 'moment';
import Template from './../models/template';
import Database from './database';
import getEnv from './utils';
import platform from 'platform';
import axios from 'axios';

export default {
    database: new Database(),
    _passwordPattern : /[a-zA-Z0-9_\-\+\.]/,
    _getRandomByte : function()
    {
        // http://caniuse.com/#feat=getrandomvalues
        if(window.crypto && window.crypto.getRandomValues) 
        {
        var result = new Uint8Array(1);
        window.crypto.getRandomValues(result);
        return result[0];
        }
        else if(window.msCrypto && window.msCrypto.getRandomValues) 
        {
        var result = new Uint8Array(1);
        window.msCrypto.getRandomValues(result);
        return result[0];
        }
        else
        {
        return Math.floor(Math.random() * 256);
        }
    },
    generatePassword(length = 20){
        return Array.apply(null, {'length': length})
        .map(function()
        {
            var result;
            while(true) 
            {
            result = String.fromCharCode(this._getRandomByte());
            if(this._passwordPattern.test(result))
            {
                return result;
            }
            }        
        }, this)
        .join('');  
    },
    ios() {
        return [
          'iPad Simulator',
          'iPhone Simulator',
          'iPod Simulator',
          'iPad',
          'iPhone',
          'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },
    hasMaxWidth(width){
        if(isNaN(width)){
            switch (width) {
                case 'xs':
                    return window.matchMedia('screen and (max-width: 600px)').matches;
                case 'sm':
                    return window.matchMedia('screen and (max-width: 960px)').matches;
                case 'md':
                    return window.matchMedia('screen and (max-width: 1280px)').matches;
                case 'lg':
                    return window.matchMedia('screen and (max-width: 1920px)').matches;
                case 'xl':
                    return window.matchMedia('screen and (max-width: 2560px)').matches;
                default:
                    return true;
            }
        }

        return window.matchMedia('screen and (max-width: '+width+'px)').matches;
    },
    slugify(text) {
        return text
            .toString()                           // Cast to string (optional)
            .normalize('NFKD')            // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
            .toLowerCase()                  // Convert the string to lowercase letters
            .trim()                                  // Remove whitespace from both sides of a string (optional)
            .replace(/\s+/g, '-')            // Replace spaces with -
            .replace(/[^\w\-]+/g, '')     // Remove all non-word chars
            .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    },
    popupCenter({url, title, w, h}) {
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;
    
        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
    
        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, title, 
          `
          location=no,toolbar=no,menubar=no,
          scrollbars=yes,
          width=${w / systemZoom}, 
          height=${h / systemZoom}, 
          top=${top}, 
          left=${left}
          `
        )
    
        if (window.focus) newWindow.focus();

        return newWindow;
    },
    geocode(search){
        //https://nominatim.org/release-docs/develop/api/Search/
        // Using fetch so no authentication header is send
        return fetch('https://nominatim.openstreetmap.org/search.php?q='+encodeURIComponent(search)+'&format=jsonv2&accept-language=nl&addressdetails=1')
        .then(response => {
            return response.json();
        }).then(json => {
            if(json && json.length > 0){
                return json[0];
            }

            return null;
        });
        
    },
    locale: 'nl',
    setLocale(locale) {
        this.locale = locale;
    },
    getLocale() {
        return this.locale;
    },
    setValueByDotName(object, path, value) {
        var way = path.replace(/\[/g, '.').replace(/\]/g, '').split('.'),
            last = way.pop();

        way.reduce(function (o, k, i, kk) {
            return o[k] = o[k] || (isFinite(i + 1 in kk ? kk[i + 1] : last) ? [] : {});
        }, object)[last] = value;
    },
    getValueByDotName(object, path) {
        let val = object;
        const parts = path.split('.');
        for (let j = 0; j < parts.length; j++) {
            const part = parts[j];

            if (val == null) {
                return null;
            }

            val = val[part];
        }
        return val;
    },
    saveLocally(store, item) {
        return this.database.put(store, item);
    },
    getSystemInfo() {

        let info = {
            type: process.env.VUE_APP_NAME,
            version: process.env.VUE_APP_VERSION,
            isInstalled: window.matchMedia('(display-mode: standalone)').matches,
            platformVersion: platform.version,
            platformName: platform.name,
            platformProduct: platform.product,
            platformManufacturer: platform.manufacturer,
            platformLayout: platform.layout,
            platformOs: platform.os.toString(),
            platformDescription: platform.description
        }

        return info;
    },
    async getLocally(store, itemId) {
        if (itemId) {
            var item = await this.database.get(store, itemId);
            if (item) {
                return item;
            }
        } else {
            var items = await this.database.getAll(store);
            if (items) {
                return items;
            }
        }

        return null;
    },
    async deleteLocally(store, itemId) {
        await this.database.delete(store, itemId);
        return true;
    },
    nl2br(str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    getRealm() {
        if (getEnv('VUE_APP_KEYCLOAK_REALM')) {
            return getEnv('VUE_APP_KEYCLOAK_REALM');
        } else {
            var hostname = window.location.hostname;
            var hostparts = hostname.split('.');
            return hostparts[0];
        }
    },
    getLocationAddress(location){
        let line = '';

            if(location.streetAddress && location.streetAddress != ''){
                line += location.streetAddress;
            }

            if(line != '' && ((location.postalCode && location.postalCode != '') || (location.locality && location.locality != ''))){
                line += ', ';
            }

            if(location.postalCode && location.postalCode != ''){
                line += location.postalCode;
            }
            
            if(location.locality && location.locality != ''){
                if(line != '' && location.postalCode && location.postalCode != ''){
                    line += ' ';
                }
                line += location.locality;
            }

            return line;
    },
    getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    },
    increaseBrightness(hex, percent) {
        return this.pSBC(percent / 100, hex);
    },
    pSBC(p, c0, c1, l) {
        let r, g, b, P, f, t, h, i = parseInt,
            m = Math.round,
            a = typeof (c1) == "string";
        if (typeof (p) != "number" || p < -1 || p > 1 || typeof (c0) != "string" || (c0[0] != 'r' && c0[0] != '#') || (c1 && !a)) return null;
        if (!this.pSBCr) this.pSBCr = (d) => {
            let n = d.length,
                x = {};
            if (n > 9) {
                [r, g, b, a] = d = d.split(","), n = d.length;
                if (n < 3 || n > 4) return null;
                x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4)), x.g = i(g), x.b = i(b), x.a = a ? parseFloat(a) : -1
            } else {
                if (n == 8 || n == 6 || n < 4) return null;
                if (n < 6) d = "#" + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : "");
                d = i(d.slice(1), 16);
                if (n == 9 || n == 5) x.r = d >> 24 & 255, x.g = d >> 16 & 255, x.b = d >> 8 & 255, x.a = m((d & 255) / 0.255) / 1000;
                else x.r = d >> 16, x.g = d >> 8 & 255, x.b = d & 255, x.a = -1
            }
            return x
        };
        h = c0.length > 9, h = a ? c1.length > 9 ? true : c1 == "c" ? !h : false : h, f = this.pSBCr(c0), P = p < 0, t = c1 && c1 != "c" ? this.pSBCr(c1) : P ? {
            r: 0,
            g: 0,
            b: 0,
            a: -1
        } : {
            r: 255,
            g: 255,
            b: 255,
            a: -1
        }, p = P ? p * -1 : p, P = 1 - p;
        if (!f || !t) return null;
        if (l) r = m(P * f.r + p * t.r), g = m(P * f.g + p * t.g), b = m(P * f.b + p * t.b);
        else r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5), g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5), b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
        a = f.a, t = t.a, f = a >= 0 || t >= 0, a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
        if (h) return "rgb" + (f ? "a(" : "(") + r + "," + g + "," + b + (f ? "," + m(a * 1000) / 1000 : "") + ")";
        else return "#" + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2)
    },
    pad(num, places) {
        return String(num).padStart(places, '0');
    },
    isUUID(text){
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(text);
    },
    getQueryFixedFilters(variables, query, template, filters){
        if(query.filters && query.filters[0]){
            for (let j = 0; j < query.filters.length; j++) {
                const filter = {...query.filters[j]};

                if(!filter.value){
                    continue;
                }

                let found = false;
                for (let k = 0; k < filters.length; k++) {
                    const existingFilter = filters[k];
                    if(existingFilter.key == filter.field.key){
                        filters[k].value = filter.value;
                        found = true;
                        break;
                    }
                }

                if(filter.value.isVariable){
                    if(variables[filter.value.id]){
                        filter.value = variables[filter.value.id].id;
                    }else{
                        continue;
                    }
                }

                if(!found){
                    filters.push({
                        key: filter.field.key,
                        value: filter.value
                    });
                }
                
                template.content.filters.push({
                    field:{
                        key: filter.field.key,
                    },
                    operator: '=',
                    value: filter.value
                });
            }
        }

        return {template:template, filters: filters};
    },
    replaceTags(content, item) {
        let tokens = this.getUsedTokens(content);

        let fields = {};
        for (let i = 0; i < tokens.length; i++) {
            const token = tokens[i];
            const tag = tokens[i].replace('{{', '').replace('}}', '');
            const parts = tag.split('.');

            let val = item;
            for (let j = 0; j < parts.length; j++) {
                const part = parts[j];
                val = val[part];
            }

            fields[token] = val;
        }

        for (const token in fields) {
            if (Object.hasOwnProperty.call(fields, token)) {
                const value = fields[token];

                let regex = token;
                let re = new RegExp(regex, "g");

                content = content.replace(re, value);
            }
        }

        return content;
    },
    getUsedTokens(content) {
        let used_tokens = [];

        var matches = content.match(/\{\{(.*?)\}\}/g);

        if (matches) {
            for (let i = 0; i < matches.length; i++) {
                const token = matches[i];
                if (!used_tokens.includes(token)) {
                    used_tokens.push(token);
                }
            }
        }

        return used_tokens;
    },
    mergeObjects(obj1, obj2) {
        let target = {};
        // Merge the object into the target object
        let merger = (obj) => {
            for (let prop in obj) {
                if (obj.hasOwnProperty(prop)) {
                    if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
                        // If we're doing a deep merge 
                        // and the property is an object
                        target[prop] = this.mergeObjects(target[prop], obj[prop]);
                    } else {
                        // Otherwise, do a regular merge
                        target[prop] = obj[prop];
                    }
                }
            }
        };

        merger(obj1);
        merger(obj2);

        return target;
    },
    randomIntFromInterval(min, max) { // min and max included 
        return Math.floor(Math.random() * (max - min + 1) + min)
    },
    parseJSON(json) {
        // preserve newlines, etc - use valid JSON
        json = json.replace(/\\n/g, "\\n")
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, "\\&")
            .replace(/\\r/g, "\\r")
            .replace(/\\t/g, "\\t")
            .replace(/\\b/g, "\\b")
            .replace(/\\f/g, "\\f");
        // remove non-printable and other non-valid JSON chars
        json = json.replace(/[\u0000-\u0019]+/g, "");

        try {
            json = JSON.parse(json);
        } catch (error) {
            json = null;
        }

        return json;
    },
    nl2br(str, is_xhtml) {
        if (typeof str === 'undefined' || str === null) {
            return '';
        }
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    getModuleNameByObjectType(objectname) {
        let settings = require('./settings');
        if (settings.objectTypes[objectname.toLowerCase()]) {
            return settings.objectTypes[objectname.toLowerCase()];
        }

        return null;
    },
    uniqueId() {
        const firstItem = {
            value: "0"
        };
        /*length can be increased for lists with more items.*/
        let counter = "123456789".split('')
            .reduce((acc, curValue, curIndex, arr) => {
                const curObj = {};
                curObj.value = curValue;
                curObj.prev = acc;

                return curObj;
            }, firstItem);
        firstItem.prev = counter;

        return function () {
            let now = Date.now();
            if (typeof performance === "object" && typeof performance.now === "function") {
                now = performance.now().toString().replace('.', '');
            }
            counter = counter.prev;
            return `${now}${Math.random().toString(16).substr(2)}${counter.value}`;
        }
    },
    round(number) {
        if (number) {
            return Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100;
        }
        return null;
    },
    getConfigurationIndex(settings, key) {
        for (let i = 0; i < settings.length; i++) {
            const setting = settings[i];
            if (setting.key == key) {
                return i;
            }
        }

        return null;
    },
    getConfigurationSetting(settings, key) {
        for (let i = 0; i < settings.length; i++) {
            if (settings[i].key == key) {
                return settings[i];
            }
        }

        return null;
    },
    getTemplateByType(templates, type) {
        for (let i = 0; i < templates.length; i++) {
            const template = templates[i];
            if (template.type == type) {
                return new Template(template);
            }
        }

        return null;
    },
    getArrayItemByProperty(arr, property, val){
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if(item[property] == val){
                return item;
            }
        }

        return null;
    },
    getTemplateMeasurements(template) {
        let measurements = [];
        for (let pageIndex = 0; pageIndex < template.content.pages.length; pageIndex++) {
            const page = template.content.pages[pageIndex];
            for (let cellIndex = 0; cellIndex < page.cells.length; cellIndex++) {
                const cell = page.cells[cellIndex];
                if (cell.typeId == 'measurementType') {
                    if (cell.settings.measurementTypeId) {
                        let found = false;
                        for (let measurementIndex = 0; measurementIndex < measurements.length; measurementIndex++) {
                            const measurement = measurements[measurementIndex];
                            if (measurement.id == cell.settings.measurementTypeId) {
                                found = true;
                                break;
                            }
                        }

                        if (!found) {
                            let data = {
                                id: cell.settings.measurementTypeId,
                            };

                            if (cell.settings.valueConfigListId) {
                                data.extraValueConfigListId = cell.settings.valueConfigListId;
                            }
                            measurements.push(data);
                        }
                    }
                }
            }
        }

        return measurements;

    },
    getFilterByKey(filters, key){
        for (let i = 0; i < filters.length; i++) {
            const filter = filters[i];
            if(filter.key == key){
                return filter;
            }
        }

        return null;
    },
    getQueryFilters(content) {
        let filters = [];
        const measurementTypeIds = [];
        const objectFilters = [];

        if (!content.dynamic_filters) {
            return filters;
        }
        
        for (let i = 0; i < content.dynamic_filters.length; i++) {
            const filter = content.dynamic_filters[i];
            if (filter.field.key == 'measurementType') {
                if (!measurementTypeIds.includes(filter.field.value)) {
                    filters.push({
                        label: filter.value,
                        measurementTypeId: filter.field.value,
                        listItems: [],
                        isHidden: filter.isHidden,
                    });
                    measurementTypeIds.push(filter.field.value);
                }
            } else if (filter.field.key) {
                if (!objectFilters.includes(filter.field.key)) {
                    filters.push({
                        label: filter.value,
                        endpoint: filter.field.endpoint,
                        key: filter.field.key,
                        isHidden: filter.isHidden,
                    });
                    objectFilters.push(filter.field.key);
                }
            }
        }

        return filters;
    },
    filtersToVariables(filters) {
        let variables = {};

        if (filters && filters.length > 0) {
            variables.where = [];
            variables.whereIn = [];
            for (let i = 0; i < filters.length; i++) {
                const filter = filters[i];

                if (!filter.result) {
                    continue;
                }

                if (filter.config.type == 'property' || filter.config.filterMethod == 'where') {
                    variables.where.push({
                        column: filter.config.filter,
                        operator: (filter.config.operator ? filter.config.operator : "ILIKE"),
                        value: filter.config.operator ? filter.result.id : '%' + filter.result.id + '%',
                        chain: 'and'
                    });
                } else if (filter.config.type == 'date') {
                    if (filter.result.id.length > 1) {
                        for (let i = 0; i < filter.result.id.length; i++) {
                            const parts = filter.result.id[i].split(':');
                            let dte = null;
                            if (parts[0] == 'date') {
                                dte = parts[1];
                            } else {
                                dte = moment().add(i == 0 ? -parts[1] : parts[1], parts[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
                            }
                            variables.where.push({
                                column: filter.config.filter,
                                operator: i == 0 ? ">=" : "<=",
                                value: dte,
                                chain: 'and'
                            });
                        }
                    } else if (filter.result.id.length == 1) {
                        variables.where.push({
                            column: filter.config.filter,
                            operator: "=",
                            value: filter.result.id[0],
                            chain: 'and'
                        });
                    }
                } else if (filter.config.type == 'date-between') {

                    let field1 = null;
                    let field2 = null;

                    if (typeof filter.config.filter == 'object') {
                        field1 = filter.config.filter[0];
                        field2 = filter.config.filter[1];
                    } else {
                        field1 = filter.config.filter;
                        field2 = filter.config.filter;
                    }


                    let date1 = filter.result.id[0];
                    let date2 = filter.result.id[1];

                    let date_from = null;
                    if (date1.type == 'fixed') {
                        date_from = moment(date1.value);
                    } else {
                        date_from = moment().add(date1.value, date1.type).startOf('day');
                    }

                    let date_until = null;
                    if (date2.type == 'fixed') {
                        date_until = moment(date2.value);
                    } else {
                        date_until = moment().add(date2.value, date2.type).startOf('day');
                    }

                    variables.where.push({
                        column: field1,
                        operator: ">=",
                        value: date_from.format('YYYY-MM-DDTHH:mm:ss'),
                        chain: 'and'
                    });

                    variables.where.push({
                        column: field2,
                        operator: "<=",
                        value: date_until.format('YYYY-MM-DDTHH:mm:ss'),
                        chain: 'and'
                    });
                } else if (filter.config.type == 'relation') {
                    if (variables.whereInRelated) {
                        const found = false;
                        for (let i = 0; i < variables.whereInRelated.length; i++) {
                            const relatedFilter = variables.whereInRelated[i];
                            if (relatedFilter.relation == filter.config.filter) {
                                found = true;
                                variables.whereInRelated.array.push(filter.result.id);
                            }
                        }

                        if (!found) {
                            variables.whereInRelated.push({
                                column: filter.config.settings.id ? filter.config.settings.id : 'id',
                                relation: filter.config.filter,
                                array: [filter.result.id]
                            });
                        }
                    } else {
                        variables.whereInRelated = [{
                            column: filter.config.settings.id ? filter.config.settings.id : 'id',
                            relation: filter.config.filter,
                            array: [filter.result.id]
                        }];
                    }

                } else if (filter.config.type == 'search') {
                    variables.search = filter.result.id;
                } else if (filter.config.type == 'active') {
                    variables.isActive = filter.result.id;
                }else if (filter.config.type == 'roles') {
                    if (!variables.roleNames) {
                        variables.roleNames = [];
                    }
                    variables.roleNames.push(filter.result.id);
                } else {
                    let found = false;
                    for (let i = 0; i < variables.whereIn.length; i++) {
                        if (variables.whereIn[i].column == filter.config.filter) {
                            variables.whereIn[i].array.push(filter.result.id);
                            found = true;
                        }
                    }

                    if (!found) {
                        variables.whereIn.push({
                            column: filter.config.filter,
                            array: [filter.result.id]
                        });
                    }

                }

            }

            if (variables.whereIn.length <= 0) {
                delete variables.whereIn;
            }

            if (variables.where.length <= 0) {
                delete variables.where;
            }
        }

        return variables;
    }
}